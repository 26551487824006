import BackgroundImage from 'gatsby-background-image';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
// UI
import H1 from '../../UI/Titles/H1';
import H3 from '../../UI/Titles/H3';
import Fonts from '../../../assets/styles/Fonts';
import { tablet } from "../../../assets/styles/Responsive";

const Wrap = styled.div`
  padding: 200px 0 70px;
  background-color: ${Colors.gin};

  @media (max-width: ${tablet}) {
    padding: 120px 0 40px;
  }
`;

const Head1 = styled(H1)`
  text-align: center;
  margin-bottom: 120px;

  @media (max-width: ${tablet}) {
    margin-bottom: 40px;
  }
`;

const Content = styled.div`
  position: relative;
`;

const BlogItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BlogItem = styled.a`
  margin-bottom: 25px;
  width: 100%;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0 !important;
  }

  @media (max-width: ${tablet}) {
    margin-bottom: 20px;
  }
`;

const BannerMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: linear-gradient(223.93deg, #446961 -15.98%, rgba(68, 105, 97, 0) 137.72%);
`;

const BlogItemImg = styled(BackgroundImage)`
  min-height: 382px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    /* background: linear-gradient(163.93deg, #3A5F57 -10.34%, rgba(162, 199, 179, 0) 175.11%); */
  }

  @media (max-width: ${tablet}) {
    min-height: 230px;
  }
`;

const BLogItemContent = styled.div`
  margin: 0 auto;
  max-width: 660px;
  text-align: center;
  z-index: 99;
`;

const BlogItemDate = styled.div`
  margin-bottom: 15px;
  color: ${Colors.aqua};
  font-size: 18px;
  line-height: 20px;
  font-family: ${Fonts.IBMRegular};

  @media (max-width: ${tablet}) {
    font-size: 16px;
  }
`;

const Head3 = styled(H3)`
  margin-top: 40px;
  font-size: 30px;
  line-height: 36px;
  color: ${Colors.aqua} !important;

  @media (max-width: ${tablet}) {
    margin-top: 15px;
    font-size: 18px;
    line-height: 21px;
  }
`;

export {
  Wrap,
  Head1,
  Content,
  BlogItems,
  BlogItem,
  BlogItemImg,
  BannerMask,
  BLogItemContent,
  BlogItemDate,
  Head3,
}