import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import MainLayout from "../components/layouts/MainLayout"

import Colors from "../assets/styles/Colors"
import Typography from "../assets/styles/Typography"
import Fonts from "../assets/styles/Fonts"
import { tablet } from "../assets/styles/Responsive"

// Components
import Seo from "../components/templates/Seo/seo"
import ArticleCard from "../components/templates/ArticleCard"
import BlogList from "../components/templates/BlogList"

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: var(--lightgreen);
  padding-top: 15vh;
  h1 {
    margin-top: 75px;
    margin-bottom: 125px;
    font-family: "Druk Wide Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    text-align: center;
    width: 100%;
  }
  @media (max-width: ${tablet}) {
    h1 {
      font-size: 25px;
    }
  }
`

const ArticleCollection = styled.div`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  max-width: calc(100% - 60px);
  margin: 0px 30px;
  padding-bottom: 72px;
`

export default function blogs({ data }) {
  const { seo } = data

  const SEOSchema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": "https://torontocannabisauthority.ca/#website",
        url: "https://torontocannabisauthority.ca/",
        name: "TORONTO CANNABIS AUTHORITY",
        description: "We\u2019ve Got The Best Weed in Toronto!",
        potentialAction: [
          {
            "@type": "SearchAction",
            target:
              "https://torontocannabisauthority.ca/?s={search_term_string}",
            "query-input": "required name=search_term_string",
          },
        ],
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": "https://torontocannabisauthority.ca/blog/#webpage",
        url: "https://torontocannabisauthority.ca/blog/",
        name: "BLOG ARTICLES - TORONTO CANNABIS AUTHORITY",
        isPartOf: { "@id": "https://torontocannabisauthority.ca/#website" },
        datePublished: "2021-05-14T09:03:28+00:00",
        dateModified: "2021-05-14T13:28:33+00:00",
        breadcrumb: {
          "@id": "https://torontocannabisauthority.ca/blog/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://torontocannabisauthority.ca/blog/"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://torontocannabisauthority.ca/blog/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://torontocannabisauthority.ca/",
              url: "https://torontocannabisauthority.ca/",
              name: "Home",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": "https://torontocannabisauthority.ca/blog/#webpage",
            },
          },
        ],
      },
    ],
  }

  return (
    <>
      <MainLayout>
        <Seo
          title="Blog Articles - Toronto Cannabis Authority"
          description="Blog Articles - Toronto Cannabis Authority"
          keywords="Blog Articles - Toronto Cannabis Authority"
          schemaMarkup={SEOSchema}
        />
        <BlogList />
      </MainLayout>
    </>
  )
}

export const query = graphql`
  query {
    allStrapiArticle {
      nodes {
        title
        published_at(formatString: "YYYY/MM/DD")
        banner {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  }
`
