// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import Typography from '../../../assets/styles/Typography';
import Fonts from '../../../assets/styles/Fonts';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
    border-radius: 25px;
    position: relative;
    margin-bottom: 25px;
    .articleImge{
        z-index: 0;
        position: absolute;
        border-radius: 25px;
        height: 100%;
    }
    .imgTag{
        border-radius: 25px;
        height: 100%;
    }

    @media (max-width: ${tablet}) {
        font-size: 18px;
        height: 230px;
    }
`;

const Text = styled.div`
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    ; 
`;

const Date = styled.div`
    font-family: 'IBM Plex Mono Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
`;

const Title = styled.h2`
    font-family: 'Druk Wide Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    //max-width: 700px;
    margin: 20px 20%;
    margin: 0 auto;
    text-align: center;

    @media (max-width: ${tablet}) {
        font-size: 18px;
    }
`;



export{
    Wrapper,
    Text,
    Date,
    Title
}