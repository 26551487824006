const getSlugArticle = (date, title) => {
  const postTitle = title.toLowerCase()
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'');

  const dateInstance = new Date(date)
  const year = dateInstance.getFullYear();
  const month = `0${dateInstance.getMonth()+1}`;
  const monthFull = month.slice(-2);
  const date1 = dateInstance.getDate();

  return `/${year}/${monthFull}/${date1}/${postTitle}`;
};

export default getSlugArticle;