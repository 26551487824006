import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// Components
import Container from '../../layouts/Container';
// Utils
import publishDate from '../../../utils/publishDate';
import getSlugArticle from '../../../utils/getSlugArticle';
// Style
import {
  Wrap,
  Head1,
  Content,
  BlogItems,
  BlogItem,
  BlogItemImg,
  BannerMask,
  BLogItemContent,
  BlogItemDate,
  Head3,
} from './styled';

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const BlogList = () => {
  const data = BlogQuery();

  return (
    <Wrap>
      <Content>
        <Container>
          <Head1 value="BLOG ARTICLES" />
          <BlogItems>
            {data.map((article) => {
              const { node: { title, banner, created_at, id } } = article;

              const image = getImage(banner.localFile?.childImageSharp?.gatsbyImageData)
              const bgimage = convertToBgImage(image);

              return (
                <BlogItem href={getSlugArticle(created_at, title)} key={id}>
                  <BlogItemImg
                    Tag="section"
                    alt={title}
                    {...bgimage}
                  >
                    <BannerMask />
                    <BLogItemContent>
                      <BlogItemDate>{publishDate(created_at)}</BlogItemDate>
                      <Head3 value={title} />
                    </BLogItemContent>
                  </BlogItemImg>
                </BlogItem>
              );
            })}
          </BlogItems>
        </Container>
      </Content>
    </Wrap>
  );
};

const BlogQuery = () => {
  const data = useStaticQuery(
    graphql`
      query BlogQuery {
        allStrapiArticle(limit: 2) {
          edges {
            node {
              id
              created_at
              title
              banner {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const { allStrapiArticle: { edges: articles } } = data;

  return articles;
};

export default BlogList;
